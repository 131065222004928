/* Aggiungi le animazioni personalizzate */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .popup-animation {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  