@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttonHover {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.root-hm {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: white;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}

.background-hm {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://parsefiles.back4app.com/h6n3hXeAyPq4Q9VYaiI1n5FnRIxoeCdlrynUbAMA/4d29e2e896a14040d16a587c0bc7f145_IMG_3258.jpeg');
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  z-index: -1;
}

.overlay-hm {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.content-hm {
  position: relative;
  animation: fadeIn 1.5s ease-in-out;
  text-align: center;
  z-index: 1;
}

.title-hm {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 16px;
  letter-spacing: 0.1em;
  animation: fadeIn 2s ease-in-out;
}

.typicalText-hm {
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 16px;
  animation: fadeIn 2.5s ease-in-out;
}

.followersContainer-hm {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  animation: fadeIn 3s ease-in-out;
}

.followersIcon-hm {
  font-size: 24px;
  margin-right: 10px;
}

.followersText-hm {
  font-size: 1rem;
}

.links-hm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  animation: fadeIn 3.5s ease-in-out;
}

.link-hm {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
  max-width: 300px;
  margin: auto;
  background: linear-gradient(45deg, #e1306c, #fd1d1d);
}

.link-hm:hover {
  animation: buttonHover 0.5s ease-in-out;
  background: linear-gradient(45deg, #fd1d1d, #e1306c);
}

.instagram-hm {
  background: linear-gradient(45deg, #e1306c, #fd1d1d);
}

.whatsapp-hm {
  background: linear-gradient(45deg, #25d366, #128C7E);
}

.news-hm {
  background: linear-gradient(45deg, #6e6e6e, #4a4a4a);
}

.altro-hm {
  background: linear-gradient(45deg, #a11d2b, #7a1420);
}

.anonymous-hm {
  background: linear-gradient(45deg, #1da1f2, #0d8ecf);
}

/* Stili per Desktop */
@media (min-width: 768px) {
  .root-hm {
    flex-direction: row;
    padding: 40px;
  }

  .content-hm {
    max-width: 800px;
    text-align: left;
  }

  .title-hm {
    font-size: 3.5rem;
    margin-bottom: 24px;
  }

  .typicalText-hm {
    font-size: 1.5rem;
    margin-bottom: 24px;
  }

  .followersText-hm {
    font-size: 1.2rem;
  }

  .links-hm {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .link-hm {
    padding: 14px 28px;
    font-size: 1.1rem;
    width: auto;
    flex-grow: 1;
    max-width: none;
  }

  .link-hm:first-child {
    flex-basis: calc(100% / 5);
  }
}





