/* home-informations.css */

/* Container delle collaborazioni */
.ihome-collab-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0;
  padding: 0 15px; /* Margini dai bordi */
}

/* Card delle collaborazioni */
.ihome-collab-card {
  background: #000; /* Sfondo nero */
  color: #fff; /* Testo bianco */
  border-radius: 50px; /* Angoli arrotondati */
  border-color: #fff;
  margin: 15px;
  padding: 20px;
  width: 100%;
  max-width: 280px; /* Card più larghe su desktop */
  box-sizing: border-box;
  text-align: center;
  transition: transform 0.3s;
}

.ihome-collab-card:hover {
  transform: translateY(-5px);
}

/* Icona della collaborazione */
.ihome-collab-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  border-radius: 50%;
  object-fit: cover;
}

/* Titolo della collaborazione */
.ihome-collab-card h3 {
  margin-bottom: 10px;
  color: #fff;
  font-size: 1.2em;
}

/* Descrizione della collaborazione */
.ihome-collab-card p {
  color: #fff;
  margin-bottom: 20px;
  font-size: 0.95em;
  line-height: 1.4;
}

/* Pulsante Instagram */
.ihome-instagram-button {
  display: inline-flex;
  align-items: center;
  background-color: #E1306C;
  color: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.ihome-instagram-button:hover {
  background-color: #C13584;
  transform: scale(1.05);
}

/* Icona Instagram nel pulsante */
.ihome-instagram-icon {
  margin-right: 8px;
  font-size: 1.2em;
}

/* Responsività */

/* Desktop: Tre card per fila */
@media (min-width: 992px) {
  .ihome-collab-card {
    flex: 1 1 calc(33.333% - 30px); /* Tre card per riga */
    max-width: none; /* Permette alle card di occupare lo spazio calcolato */
  }
}

/* Tablet: Due card per fila */
@media (min-width: 600px) and (max-width: 991px) {
  .ihome-collab-card {
    flex: 1 1 calc(50% - 30px);
    max-width: none;
  }
}

/* Mobile: Una card per fila */
@media (max-width: 599px) {
  .ihome-collab-card {
    flex: 1 1 100%;
    max-width: none;
    margin: 15px 0;
  }
}


  