/* treniStazione.css */
.zcns-container {
  margin-bottom: 1rem; /* mb-4 */
}

.zcns-label {
  display: block; /* block */
  color: #4a5568; /* text-gray-700 */
  margin-bottom: 0.5rem; /* mb-2 */
}

.zcns-select-input {
  width: 100%; /* w-full */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.zcns-menu {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid ;
  border-radius: 4px;
  background-color: white;
  position: center;
  width: 100%;
  z-index: 1000;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.zcns-menu-item {
  padding: 8px;
  cursor: pointer;
}

.zcns-highlighted-item {
  background-color: #bde4ff;
}


  
  