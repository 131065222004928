
  .ihome-container {
    width: 90%;
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
    padding: 20px;
  }
  
  /* Sezione Hero */
  .ihome-hero {
    background-size: cover;
    background-position: center;
    padding: 100px 0;
    color: #fff;
    text-align: center;
    position: relative;
  }
  
  .ihome-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
  }
  
  .ihome-hero-content {
    position: relative;
    z-index: 1;
  }
  
  .ihome-hero-title {
    font-size: 60px;
    margin-bottom: 20px;
    animation: fadeInDown 1s ease-in-out;
  }
  
  .ihome-hero-subtitle {
    font-size: 24px;
    margin-bottom: 30px;
    animation: fadeInUp 1s ease-in-out;
  }
  
  .ihome-hero-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #e91e63;
    color: #fff;
    text-decoration: none;
    border-radius: 50px;
    font-size: 18px;
    transition: background-color 0.3s ease;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .ihome-hero-button:hover {
    background-color: #d81b60;
  }
  
  /* Sezione Chi Siamo */
  .ihome-about {
    background-color: #f5f5f5;
    padding: 60px 0;
    text-align: center;
  }
  
  .ihome-title {
    color: #333;
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .ihome-description {
    color: #666;
    font-size: 18px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto 40px auto;
  }
  
  /* Sezione Cosa Facciamo */
  .ihome-services {
    background-color: #fff;
    padding: 60px 0;
  }
  
  .ihome-subtitle {
    color: #333;
    font-size: 32px;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .ihome-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .ihome-card {
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    padding: 30px;
    margin: 20px;
    text-align: center;
    flex: 1 1 300px;
    max-width: 300px;
    transition: transform 0.3s ease-in-out;
  }
  
  .ihome-card:hover {
    transform: translateY(-10px);
  }
  
  .ihome-icon {
    font-size: 50px;
    color: #e91e63;
    margin-bottom: 20px;
  }
  
  .ihome-card h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .ihome-card p {
    font-size: 16px;
    color: #555;
  }
  
  /* Sezione Collaborazioni */
  .ihome-collaborations {
    background-color: #f5f5f5;
    padding: 60px 0;
    text-align: center;
  }
  
  .ihome-collaboration-description {
    color: #666;
    font-size: 18px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Sezione Contattaci */
  .ihome-contact-section {
    background-color: #fff;
    padding: 60px 0;
    text-align: center;
  }
  
  .ihome-contact {
    color: #333;
    font-size: 18px;
  }
  
  .ihome-contact-icon {
    font-size: 40px;
    color: #e91e63;
    margin-bottom: 20px;
  }
  
  .ihome-contact p {
    margin: 5px 0;
  }
  
  /* Animazioni */
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Responsività */
  @media (max-width: 768px) {
    .ihome-hero-title {
      font-size: 36px;
    }
  
    .ihome-hero-subtitle {
      font-size: 18px;
    }
  
    .ihome-title,
    .ihome-subtitle {
      font-size: 28px;
    }
  
    .ihome-card {
      margin: 20px auto;
    }
  
    .ihome-icon {
      font-size: 40px;
    }
  }
  


